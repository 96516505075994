
.terminos-container{
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
}
.terminos-container .terminos{
    border-radius: 6px;
    box-shadow: 0px 0px 9px 0px lightgrey;
    max-height: 700px;
    min-height: 600px;
    padding: 50px 5vw;
    width: 500px;
}
.terminos-container .terminos .h1{
    font-family: 'Nunito', sans-serif;
    color: #008eff;
    margin-bottom: 20px;
}
.terminos-container .terminos .content{
    font-family: 'Nunito', sans-serif;
    height: 450px;
    overflow: scroll;
    border-top: 2px solid #008eff;
    padding-top: 20px;
}
.terminos-container .terminos .content .span{
    font-family: 'Nunito', sans-serif;
}
.terminos-container .terminos .content .span.point{
    color: #004074;
}