.profile p {
    color: #2c2c2c;
}
.profile *{
    font-family: 'Nunito', sans-serif;
}

.profile{
    align-items: flex-start;
    background-color: aliceblue;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

/* ............. Scciones Profile ............. */
.profile__right {
    border-radius: 0px;
    padding: 50px 100px;
    width: 80%;
}
.profile__left.mostrar{
    display: flex;
    z-index: 2;
    width: 100%;
    position: absolute;
}
.profile__left .cancel{
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    background-color: #ff3838;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 25px;
    height: 25px;
    top: 10px;
    right: 10px;
    z-index: 3;
}
.profile__left .cancel:hover{
    background-color: #c90000;
}
/* Mascota */
.profile__sameLine{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.profile__right-mascotas{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
/* Perfil */
.profile__editarPerfil{
    font-size: 20px;
    border-left: 3px solid #0072ff;
    padding-left: 20px;
}
.profile__right-perfil{
    display: flex;
    flex-direction: column;
}
.profile__seccion1{
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 50px;
}
.profile__seccion1 .profile__img {
    height: 250px;
    width: 250px;
    position: relative;
}
.profile__seccion1 .profile__img img {
    border-radius: 2px;
    height: auto;
    width: 100%;
}
.profile__seccion1 .profile__info{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 40%;
}
.profile__perfil-label {
    margin-top: 10px;
    color: #2c2c2c;
}
 /* Sección 2 */
.profile__section2{
    margin-top: 50px;
    width: 30%;
}
.profile__section2 p{
    font-size: 20px;
    border-left: 3px solid #0072ff;
    padding-left: 20px;
}
/* Citas */
.profile__citas{
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}






.profile__section{
    color: #006eff;
}
.profile__left{
    align-items: center;
    background-color: white;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 20%;
}



/* .......... MASCOTAS .......... */
.profile__misMascotas {
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: 210px;
    width: 100%;
    scroll-behavior: auto;
}
.mascotaCard__button{
    height: 190;
    width: auto;
}
.mascotaCard__mascota:hover{
    transition: .7s;
    background-color: #00000000;
    background-position: 20px;
}


.mascotaCard{
    border-radius: 6px;
}

.mascotaCard h1,h2,p{

    font-family: 'Nunito', sans-serif;
}


.mascotaCard__mascota {
    align-items: flex-end;
    background-color: #0000007a;
    box-shadow: 0px 0px 9px 0px #5a5a5a;
    border-radius: 6px;
    display: flex;
    height: 190px;
    transition: .4s;
    width: 250px;
}

.mascotaCard__info {
    color: white;
    background-color: #000000af;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    font-weight: 100;
    letter-spacing: 1px;
    padding: 10px 15px;
    text-align: left;
    transition: .4s;
    width: auto;
}

.mascotaCard__info h2{
    font-size: 15px;
    font-weight: 100;

}

.perfil__mascotas-empty{
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 200px;
    width: 100%;
}
.perfil__mascotas-empty img{
    width: 20%;
}
.perfil__mascotas-empty a{
    text-decoration: none;
    color: #006eff;
    font-weight: 800;
}
.perfil__mascotas-empty a:hover{
    text-decoration: underline;
    transition: .4s;
    color: #003a85;
}

/* ....................... PHOTO ....................... */
.photoProfile__background-img{
    border-radius: 0;
    height: auto;
    width: 100%;
}
.photoProfile{
    background-color: #5a5a5a;
    border: 2px solid white;
    border-radius: 100%;
    height: 150px;
    margin-top: -70px;
    width: 150px;
    z-index: 1;
}
.photoProfile img{
    border-radius: 100%;
    height: auto;
    width: 100%;
}
@supports(object-fit: cover){
    .photoProfile img, .profile__seccion1 .profile__img img, .photoProfile__background-img {
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
}
.photoProfile__background{
    filter: brightness(45%);
    height: 30%;
    width: 100%;
}

/* ........... Secciones de Perfil ........... */
.profile__btnProfile {
    background-color: #ffffff;
    border-radius: 0px;
    border-left: 3px solid #0072ff;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    color: #4a4a4a;
    margin-left: 5px;
    width: 100%;
    height: 40px;
    text-align: left;
}
.profile__btnProfile:hover {
    background-color: #d9eaff;
    transition: background-color .4s;
}
.profile__titleBtn {
    align-items: center;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    display: flex;
    padding: 10px 20px;
    height: 100%;
    width: 100%;
}
.perfil_active{
    background: #0072ff;
    color: white;
}
.perfil_active:hover{
    background: #0072ff;
    background-position-x: 10%;
    color: white;
}

.mt-10{
    margin-top: 10%;
}
   
#spiner-home {
    width: 100px;
    height: 100px;
    border-bottom: 7px dotted #96ff96;
    border-left: 7px dotted #0072ff;
    top: 45vh;
    left: 60vw;
}

#perfil__logout {
    position: absolute;
    color: #ff6767;
    align-items: center;
    border: none;
    border-radius: 5px;
    border: 1px solid #ff6767;
    right: 15px;
    display: flex;
    padding: 10px 0px;
    height: 35px;
    width: 125px;
    top: 15px;
}
#perfil__logout:hover{
    background-color: #ff6767;
    border-radius: 4px;
    color: white;
    transition: 1s;
}

#perfil__logout:focus{
    background-color: #ff2424;
    border: 1px solid #ff2424;
    color: white;
}

.perfil__editImage {
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    background: #0000006e;
    border: dashed 2px #ffffff;
    z-index: 1000;
    height: 250px;
    width: 100%;
    top: 0;
    font-size: 4rem;
    color: white;
}
.perfil__editImage:hover{
    background-color: #000000ba;
    transition: .4s;
}

#formUpdateUser{
    gap: 0;
}

.selectSexoUserUpdate{
    margin-top: 10px;
}

.profile__editarPerfil.cc{
    border-left: 3px solid lightgreen;
    font-size: 15px;
    margin-top: 5px;
}
#login-spin-sectionPerfil{
    border-bottom: 7px solid#cbe0ff;
    border-top: 7px solid #cbe0ff;
    border-left: 7px solid#008eff;
    border-right: 7px solid #cbe0ff;
    position: relative;
    width: 100px;
    height: 100px;
}

.loading_sectionPerfil {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
#formUpdateUser .error_actualizar_usuario{
    color: red;
    margin-top: 10px;
}

.profile__right-perfil .updatePasswordError {
    font-size: 14px;
    border-left: 3px solid #f44336;
    padding-left: 20px;
}

/* .............. Modal Mascotas .............. */
.mascotaInfo__container .name{
    font-family: 'Nunito', sans-serif;
}
.mascotaInfo__container{
    align-items: center;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 400px;
    height: auto;
    padding-bottom: 1rem;
    position: relative;
    z-index: 1000;
}
.mascotaInfo__container .name{
    color: #2196f3;
    font-size: 1.5rem;
}
.mascotaInfo__container .name .tipo{
    font-family: 'Nunito', sans-serif;
    color: #858585;
    font-size: 1rem;
}
.mascotaInfo__container .img{
    animation: 1s imagenes ease;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 3px solid #008eff;
    object-fit: cover;
    overflow: hidden;
    width: 400px;
    height: 200px;
}
.mascotaInfo__container .container__updateImg{
    height: 200px;
    position: absolute;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mascotaInfo__container .updateImg{
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    padding: 20px;
    width: 120px;
    height: 120px;
    background: #00000073;
    border-radius: 50%;
}
.mascotaInfo__container .updateImg:hover{
    border-radius: 5px;
    background-color: #00000078;
    width: 400px;
    height: 200px;
    transition: 1s;
}
.mascotaInfo__container .specificInfo{
    width: 300px;
}
.mascotaInfo__container .specificInfo #icon{
    margin-bottom: -3px;
}
.mascotaInfo__container .cancel{
    align-items: center;
    display: flex;
    background-color: #ff4545;
    border-radius: 50%;
    color: white;
    position: absolute;
    justify-content: center;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
}
.mascotaInfo__container .edit{
    align-items: center;
    display: flex;
    background-color: #008eff;
    border-radius: 50%;
    color: white;
    position: absolute;
    justify-content: center;
    top: 40px;
    right: 10px;
    width: 25px;
    height: 25px;
}
.mascotaInfo__container .gender{
    align-items: center;
    display: flex;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    justify-content: center;
    top: 10px;
    left: 10px;
    width: 25px;
    height: 25px;
}
.mascotaInfo__container .icon_gender{
    font-size: 17px;
}
.mascotaInfo__container .cancel:hover{
    background-color: white;
    color: #ff4545;
    transition: .4s;
}
.mascotaInfo__container .edit:hover{
    background-color: white;
    color: #008eff;
    transition: .4s;
}

@keyframes imagenes {
    from{
        filter: blur(1);
        opacity: 0;
    }
    to{
        filter: blur(0);
        opacity: 1;
    }
}

.mascotaInfo__container .peso{
    position: absolute;
    top: 210px;
    right: 10px;
    font-size: 13px;
}
.mascotaInfo__container .color{
    position: absolute;
    top: 230px;
    right: 10px;
    font-size: 13px;
}
#iconPesoEdit{
    font-size: 15px;
    margin-bottom: -3px;
    margin-left: 5px;
}
.mascotaInfo__container .sameLine{
    display: flex;
    justify-content: space-between;
    gap: 7px;
}
.mascotaInfo__container label{
    font-size: 11px;
    color: #4a4a4a;
}

.mascotaInfo__container #icon-edit{
    cursor: pointer;
    margin-left: 5px;
    margin-bottom: -4px;
}
#sexoEdit{
    width: 95px;
    height: 30px;
    padding: 4px 10px;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size: 13px;
    color: #1d2129;
}
#sexoEdit:focus{
    outline: 1px solid #dddfe2;

}
.mascotaInfo__container .validacionForm{
    font-size: 14px;
    color: red;
    border-left: 2px solid red;
    padding-left: 15px;
}
.mascotaInfo__container .center{
    margin: 10px 0px;
    align-self: center;
}
.mascotaInfo__container .validacionFormSuccess{
    font-size: 14px;
    color: green;
    border-left: 2px solid green;
    padding-left: 15px;
}
.boton_despliegue-profile{
    cursor: pointer;
    position: fixed;
    top: 10px;
    left: 10px;
    padding: 4px;
    background-color: #008eff;
    border-radius: 50%;
    color: white;
    font-size: 20px;
}
.profile__left .left-row{
    cursor: pointer;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1;
    background-color: #008eff;
    color: white;
    border-radius: 50%;
}
.profile__right.ocultar{
    display: none;
}
.boton_despliegue-profile:hover,.profile__left .left-row:hover{
    transition: .4s;
    background-color: #5fb7ff;
}
.boton_despliegue-profile.block{
    display: none;
}

.verifyPassword{
    background-color: white;
    width: 350px;
    padding: 50px;
    z-index: 1000;
    display: flex;
    align-items: center;
    position: relative;
}
.verifyPassword .label{
    font-size: 12px;
    font-family: 'Nunito', sans-serif;
}

p.cancelPasswordVerify {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s;
    border-radius: 50%;
}
p.cancelPasswordVerify:hover {
    background-color: #ff5454;
    color: white;
    transition: .4s;
}