/*.....................Footer.............................*/
footer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    flex-direction: column;
    justify-content: space-around;
   
}
footer hr{
    width: 50%;
}


.footerIcon{
    display: flex;
    justify-content: center;

}

.footerIcon .sameLine{
    display: flex;
    gap: 20px;
    width: 85vw;
    align-items: center;
    justify-content: center;
}
.footerIcon .sameLine .hr{
    width: 100%;
    height: 1px;
    background: #0088ff;
    border: none;
}
.footerIcon .sameLine .icon{
    font-size: 90px;
}
.facebook{
    color: #0088ff;
}
.instagram{
    color: #E1306C;
}
.huesito{
    width: 40px;
    border-radius: 0%;
}
.copy{
    font-size: 15px;
    margin-top: 16px;
}

.politicas{
    display: flex;
    gap: 5px;
    align-items: center;
}


a{
    margin-top: 5px;
    text-decoration: none;
    color: black;
}
a:hover{
    color: #0088ff;
}