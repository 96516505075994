
.MenuHome__div {
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: auto;
    position: fixed;
    right: 1rem;
    top: 4rem;
    width: 12vw;
    min-width: 120px;
    z-index: 1001;
}
.MenuHome__div::after{
    content: '';
    border-top: none;
    border-bottom: 10px solid white;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    right: 16px;
    top: -9px;
}
.MenuHome__perfil{
    color: #444444;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 1px solid #5a5a5a;
    border-right: 1px solid #5a5a5a;
    border-left: 1px solid #5a5a5a;
    /* border-bottom: 1px solid #a2a2a2; */
    cursor: pointer;
    padding: 10px 11px;
}
.MenuHome__logout{
    color: #ff4242;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid #ff6a6a;
    border-bottom: 1px solid #ff6a6a;
    border-right: 1px solid #ff6a6a;
    border-left: 1px solid #ff6a6a;
    cursor: pointer;
    padding: 10px 11px;
}
.MenuHome__perfil.cli{
    border-radius: 0px;
    border-right: 1px solid #5a5a5a;
    border-left: 1px solid #5a5a5a;
    border-bottom: none;
    border-top: none;
}
.MenuHome__perfil:hover{
    background-color: #4196ff;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    color: white;
    transition: .4s;
}
.MenuHome__perfil.cli:hover{
    background-color: #4196ff;
    border-radius: 0px;
    color: white;
    transition: .4s;
}
.MenuHome__logout:hover{
    background-color: #ff6a6a;
    border: 1px solid #5a5a5a;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: white;
    transition: .4s;
}