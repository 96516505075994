.home__contenedor h1, p{
    font-family: 'Nunito', sans-serif;
}
header h1,p{
    font-family: 'Nunito', sans-serif;
}
html{
    scroll-behavior: smooth;
}
.nave{
    width: 50px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    

}

.nave:hover{
    transform: scale(1.1);
}
header {
    background: linear-gradient(360deg, #0051b5,#0072ff);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    padding: 3% 6% 7%;
    overflow: hidden;
}
.headerHome__left{
    width: 50%;
}
.headerHome__left p {
    font-size: 19px;
    color: white;
    margin-bottom: 20px;
    letter-spacing: 1px;
    line-height: 31px;
    width: 80%;
}
.headerHome__left h1 {
    color: white;
    font-size: 7rem;
    font-family: 'Bebas Neue', cursive;
    margin-bottom: 1%;
    letter-spacing: 5px;
    width: 90%;
}

.headerHome__right{
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;
}


.headerHome__right img{
    position: relative;
    width: 70%;
}

.headerHome__span{
    color: #70b0ff;
    font-family: 'Bebas Neue', cursive;
    text-shadow: 5px 5px #333;
}


.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: url("https://res.cloudinary.com/samuelrm5/image/upload/v1663092345/wave_abrvue.png");
    background-size: 1000px 70px;
}
header .wave.wave1{
    animation: animate 40s linear infinite;
    z-index: 1000;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
}
header .wave.wave2{
    animation: animate2 20s linear infinite;
    z-index: 999;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
}
header .wave.wave3{
    animation: animate 40s linear infinite;
    z-index: 997;
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 20px;
}
header .wave.wave4{
    animation: animate2 15s linear infinite;
    z-index: 998;
    opacity: 0.2;
    animation-delay: -2s;
    bottom: 15px;
}


@keyframes animate {
    0%{
        background-position-x: 0;
    }
    100%{
        background-position-x: 1000px;
    }
}
@keyframes animate2 {
    0%{
        background-position-x: 0;
    }
    100%{
        background-position-x: -1000px;
    }
}


.home__aboutUs{
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    padding: 7%;
    width: 100%;
}
.home__aboutUs span{
    color: #ffaf6a;
    font-family: 'Nunito', sans-serif;
}
.home__aboutUs-img {
    display: flex;
    justify-content: flex-end;
    height: 400px;
    width: 45%;
}
.home__aboutUs-img img{
    border-radius: 5px;
    height: auto;
    width: 85%;
}
.home__aboutUs-text{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.home__aboutUs h1{
    font-size: 4rem;
    margin-bottom: 50px;
    width: 100%;
}
.home__aboutUs p{
    color: #363636;
    font-size: 20px;
    width: 85%;
}


.home__proyecto{
    background-color: aliceblue;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    padding: 7%;
    width: 100%;
}
.home__proyecto span{
    color: #0072ff;
}
.home__proyecto-text{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.home__proyecto-img{
    display: flex;
    justify-content: flex-start;
    width: 40%;
}
.home__proyecto-img img{
    border-radius: 5px;
    height: auto;
    width: 80%;
}

.home__tiempo{
    height: auto;
}


.wave__contactUs{
    margin-top: -190px;
    width: 100%;
}
.home__contactUs{
    align-items: center;
    background-color: #0072ff;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    padding: 0px 7%;
    width: 100%;
}
.home__contactUs-title {
    background-color: #0072ff;
    color: white;
    font-size: 4rem;
    height: 200px;
    margin-top: -10px;
    text-align: center;
    width: 100%;
}
.home__contactUs-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 0px 9px 0px #4c4c4c;
    display: flex;
    flex-direction: column;
    height: 350px;
    margin-bottom: 50px;
    width: 250px;
}
.home__contactUs-card-img{
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}
.home__contactUs-card-img img{
    border-radius: 6px;
    width: 100%;
    height: auto;
}
@supports(object-fit: cover){
    .home__contactUs-card-img img, .home__contactUs-card-img img{
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
}

.home__contactUs-card-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 350px;
    padding: 30px 20px;
    position: absolute;
    width: 250px;
    z-index: 0;
}
.home__contactUs-card-info h1{
    color: white;
    font-size: 25px;
}
.home__contactUs-card-info img{
    border-radius: 0px;
    width: 30px;
}
.home__contactUs-card-info p{
    color: rgb(164, 164, 164);
}

.home__contactUs-card:hover .home__contactUs-card-info{
    background-color: #000000bb;
    border-radius: 6px;
    transition: .8s;
    z-index: 2;
}
.home__contactUs-card-info-type{
    height: 30px;
    text-align: center;
}
.home__contactUs-card-info-type p{
    color: white;
}
.home__contactUs-card-info-media{
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}
.home__contactUs-card-info-media a{
    align-items: center;
    display: flex;
    justify-content: center;
    width: 20%;
}
.home__contactUs-card-info-media img{
    border-radius: 100%;
    filter: brightness(50%);
    width: 100%;
}
.home__contactUs-card-info-media img:hover{
    filter: none;
    transition: .5s;
}
#image-team{
    width: 100%;
}
