*{
    margin: 0;
    padding: 0;
}
#liHome{
    list-style: none;
    width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.aNavHome {
    transition: 0.5s;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    width: 100px;
    height: 40px;
    border-radius: 4px;
}
.logIn{
    color: #ffffff;
    font-weight: 600;
}
.signIn{
    font-weight: 600;
    background-color: #00376c;
    color: aliceblue;

}
.logIn:hover{
    transition: .7s;
    background-color: white;
    color: #0072ff;
    border: none;
}
.signIn:hover{
    transition: .7s;
    background-color: white;
    color: #0072ff;
}
.headerHome {
    background-color: #0072ff;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
#header__logo{
    border-radius: 0;
    width: 90px;
}

#header__title{
    border-radius: 0;
    width: 180px;
    cursor: pointer;
}
.nav_Home{
    display: flex;
    align-items: center;
}
.logoHome{
    width: 120px;
}
.tituloHome{
    width: 250px;
}
.logo{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.home{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home__autenticado {
    align-items: center;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 42px;
    gap: 10px;
    padding: 5px 10px;
    position: fixed;
    top: 0.5rem;
    right: 1rem;
    width: auto;
    z-index: 1001;
}
.home__autenticado:hover {
    background-color: white;
    box-shadow: 2px 3px 2px 0px #00376c;
    transition: .5s;
    border: 1px solid #00376c;
}
.home__autenticado h1 {
    color: black;
    font-size: 17px;
    font-weight: 500;
    width: auto;
}

.home__autenticado img{
    border-radius: 100%;
    height: 40px;
    width: 40px;
}