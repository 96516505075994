.registro_mascota select{
    width: 100%;
    height: 50px;
    padding: 14px 16px;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size: 17px;
    color: #1d2129;
}

.div_registro_mascotas_select{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: flex-end;
}

.div_registro_mascotas_select_gender{
    width: 100%;
    height: auto;
}

#select_type,#select_gender:focus-visible{
    outline: none;
}

.div_select_type{
    width: 100%;
}

#option_disabled{
    background-color: #e0e0e0;
}

#p_registro_mascotas {
    padding: 0;
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 16px;
    color: rgb(99, 99, 99);
    display: flex;
    width: 100%;
    align-items: center;
    height: 15px;
}

.div_registro_mascotas_input{
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
}

#genderMale{
    font-size: 25px;
    color: #2b87d5;
    font-weight: bold;
}

#genderFemale{
    font-size: 25px;
    color: #e66afd;
    font-weight: bold;
}


.ocultar_elemento{
    display: none;
} 

.mostrar_elemento{
    display: block;
}

.deshabilitar{
    pointer-events: none;
}

#FcInfo{
    width: 10%;
    height: auto;
    cursor: pointer;
    color: #2b87d5;
}

.img_registro_mascotas {
    border-radius: 0;
    width: 330px;
    margin-bottom: 20px;
}
.registroMascotas__button-div{
    display: flex;
    gap: 5px;
    width: 100%;
}

.registroMascotas__div-peso{
    color: rgb(99, 99, 99);
    display: flex;
    height: auto;
    gap: 10px;
    width: 95%;
}

.registroMascotas__div-peso .peso {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
    width: 50%;
}

.registroMascotas__div-peso .peso input {
    border: none;
    border-radius: 3px;
    padding: 0px 5px;
    text-align: center;
    width: 50px;
    margin: 0px 10px;
}

.registroMascotas__div-peso .discapacidad{
    align-items: center;
    display: flex;
    gap: 10px;
    width: 50%;
}
.registroMascotas__div-peso .discapacidad input{
    width: 15px;
    height: 15px;
}

#warn__password-mascota {
    color: red;
    font-size: 15px;
    height: 15px;
    margin-top: -15px;
    padding: 0;
    width: 95%;
}

