.loginContainer{
    width: 100%;
    height: 100vh;
    background-color: #f0f2f5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;
    gap: 5%;
}

#formLogin__badData {
    color: red;
    padding: 0;
}

.formLogin {
    align-items: center;
    display: flex;
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: auto;
    width: 100%;
}

#eslogan{
    width: 280px;
}

.login_cont_dr{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
}

.login_cont_iz{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 396px;
    text-align: center;
}

.login_cont_iz img{
    width: 400px;
}

.login_cont_iz p{
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 20px;
    border-left: 4px double #a9a9a9;
    border-right: 4px double #a9a9a9;
    color: #000052;
    width: 80%;
}
.loginData button{
    font-family: 'Nunito', sans-serif;
}

.loginData p{
    color: black;
}

.loginData{
    background-color: rgb(255, 255, 255);
    width: 396px;
    height: auto;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 0 7px 0px lightgray;
    gap: 5px;
}

.loginData p{
    font-size: 18px;
    padding: 10px 0 20px 0;
}

.hr{
    align-items: center;
    color: #1d212961;
    display: flex;
    flex-direction: row;
    font-size: 13px;
    gap: 15px;
    justify-content: center;
    width: 100%;
}

.hr hr{
    width: 100%;
    border: none;
    background-color: #1d212961;
    height: 1px;
}

.enlaceBtn{
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
}

#warn-login {
    color: red;
    font-size: 15px;
    margin-top: -10px;
    padding: 0px;
    width: 100%;
}

#login-spin {
    border-bottom: 2px solid #008eff;
    border-top: 2px solid #008eff;
    border-left: 2px solid #ffffff00;
    border-right: 2px solid #ffffff00;
    position: relative;
    width: 20px;
    height: 20px;
}

#login__forgotPassword {
    align-self: flex-start;
    color: #ff7f26;
    cursor: pointer;
    font-size: 15px;
    padding: 0;
    text-decoration: underline;
    width: auto;
}
#login__forgotPassword:hover{
    color: #cc5500;
    transition: .4s;
}



#btnh30 {
    height: 30px;
    font-size: 1rem;
    padding: 6px 22px;
    width: 80px;
    font-weight: 100;
}

.perfil__statusUser{
    align-items: center;
    background-color: white;
    border-radius: 20px;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: auto;
    z-index: 1000;
}
.perfil__statusUser h1{
    color: #ffffff;
    font-size: 25px;
    font-weight: 100;
    width: 100%;
    text-align: center;
    background: #008eff;
    padding: 15px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.perfil__statusUser p{
    font-size: 1rem;
    text-align: center;
    width: 230px;
    margin: 0px 20px;
    height: auto;
}

.login_password_div{
    width: 100%;
    height: auto;
    position: relative;
}
.login_password_div .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border-radius: 50%;
    color: #008eff;
    height: 30px;
    font-size: 1.5rem;
    right: 10px;
    top: 10px;
    position: absolute;
    width: 30px;
}