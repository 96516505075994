@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Ruda:wght@400;900&display=swap');
*{
    margin: 0;
    padding: 0;
    scrollbar-color: #008eff rgba(0, 0, 0, 0);
    scrollbar-width: thin;
}
.st2 h1,h2,h3,h4,h5,h6,input,button,span{
    font-family: 'Ruda', sans-serif;
    color: #008eff;
}
.titles{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}
.title-container{
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 85%;
}
#titleP1{
    color: #008eff;
    font-family: 'Ruda', sans-serif;
    font-size:  7vh;
}
#titleP2{
    color: #008eff;
    font-family: 'Ruda', sans-serif;
    font-size:  3vh;
    margin-left: 25px;
}
#titleP3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.img_logo_clinica{
    width: 150px;
    object-fit: contain;
}
#titleP3 h1{
    font-size:  4vh;
}
#titleP2-margin{
    font-size:  3vh;
}
.titleP2-margin{
    color: #e9e9e9;
    font-family: 'Ruda', sans-serif;
    font-size:  4vh;
    padding: 2rem;
}
.st1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
}
.st2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    width: 99%;
    height: 98%;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
}
.st3{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}
.st4{
    overflow-y: scroll;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
    width: 35%;
    height: 82vh;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff73;
}
.st5{
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    margin-left: 5px;
    margin-right: 5px;
    padding: 25px;
    width: 55%;
    height: 82vh;
    border-radius: 6px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffffa8;
    overflow-y: scroll;
}
/* --------------------------------- */
::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width:10px;
}

::-webkit-scrollbar-button:increment,::-webkit-scrollbar-button {
    display: none;
} 

::-webkit-scrollbar:horizontal {
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #008eff;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

::-webkit-scrollbar-track {
    border-radius: 10px;  
}
/* --------------------------------- */
span{
    color: #34007985;
}
.img_li_vet{
    height: 70px;
    width: 70px;
}
#imgVet{
    height: 100%;
    width: 100%;
    border-radius: 360px;
    object-fit: contain;
}
#imgLi{
    transition: 0.2s;
    width: 65px;
    padding-right: 10px;
}
#imgLi:hover{
    transition: 0.2s;
    filter: brightness(50%); 
}
.liVetSpace{
    background-color: rgb(255, 255, 255);
    transition: 0.5s;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    color: #616161;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    width: 60vh;
}
.liVet{
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.liVetA{
    margin-left: 20px;
}
.bottomForm{
    display: flex;
    flex-direction: row;
    gap: 25px;
    margin-top: 5vh;
}
.formVet{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
    height: 85vh;
    align-items:  center;
}

/* ------------------- */
.input-container{
    height: 50px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ic1 {
    margin-top: 40px;
}
.ic2 {
    margin-top: 30px;
}
.input {
    width: 70vh;
    height: 50px;
    padding: 14px 16px;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size: 17px;
    color: #1d2129;
}
.cut {
    background-color: #ffffff;
    border-radius: 10px;
    height: 20px;
    right: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 250px;
    text-align: center;
}
.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
    transform: translateY(8px);
}
.placeholder {
    color: #65657b;
    line-height: 14px;
    right: 0;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 20px;
    width: 255px;
    text-align: center;
    font-family: 'Ruda', sans-serif;
    font-size: 15px;
}
.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder{
    transform: translateY(-30px) translateX(10px) scale(0.75);
}
  
.input:not(:placeholder-shown) ~ .placeholder {
    color: #272727;
    font-size: 20px;
}
  
.input:focus ~ .placeholder {
    font-weight: bold;
    font-size: 20px;
    color: #008eff;
}
#id{
    background-color: #00779b44;
}
.submit {
    background-color: #008eff;
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    margin-top: 38px;
    text-align: center;
    width: 20vh;
}
  
.submit:active {
    background-color: #06b;
}
.inputID{
    background-color: #ffffff;
    border-radius: 12px;
    border: 0.5px solid black;
    box-sizing: border-box;
    color: rgb(3, 3, 3);
    font-size: 25px;
    height: 100%;
    outline: 0;
    padding: 4px 20px 0;
    width: 40vh;
}
.iSearch{
    margin-top: 1rem;
    margin-left: 2rem;
    margin-bottom: 1rem;
    width: 30vh;
}
/* ------------------ */
.inputsVet{
    display: flex;
    flex-direction: column; 
    gap: 20px;
}
#titleValidation{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    width: 40vh;
}
.imgWait{
    width: 180px;
}
#searchIcon{
    transition: 0.2s;
    width: 32px;
    border-radius: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    z-index: 9;
    right: 40px;
    position: relative;
}
.search{
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
}
.idSection{
    width: 20vh;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.idSection h3{
    font-size: 40px;
    color: #008eff;
}
.btnSection{
    margin-bottom: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20vh;
}
#warningP{
    font-size: 15px;
    font-weight: bold;
    color: #ff0000;
    font-family: 'Ruda' sans-serif;
    margin: 0;
    padding: 0;
}
#succesP{
    font-size: 25px;
    font-weight: bold;
    color: #61bbd6;
    margin: 0;
    padding: 0;
    font-family: 'Ruda' sans-serif;
}
.hrVet{
    width: 60vh;
    border-radius: 100%;
    height: 2px;
    color: #008eff;
}
#login-spin-clinic{
    border-bottom: 2px solid #008eff;
    border-top: 2px solid #008eff;
    border-left: 2px solid #ffffff00;
    border-right: 2px solid #ffffff00;
    position: relative;
    width: 20px;
    height: 20px;
}
.regs{
    margin: 0;
    padding: 0;
}

#imgForm{
    height: 100%;
    width: 100%;
    border-radius: 360px;
    object-fit: contain;
}
.imgForm{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
}
.img_cont_vet{
    transition: 0.2s;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    height:200px;
    width: 200px;
    cursor: pointer;
}
.img_cont_vet:hover{
    transition: 0.2s;
    filter: brightness(50%); 
}
.notFound{
    height: 10vh;
    display: flex;
    justify-content: center ;
}
.succes{
    color: green;
}
/* ESTADOS */
.active{
    color: green;
}
.inactive{
    color: tomato;
}

.modal_active_inactive{
    background-color: #dee1e2;
    width: 50vh;
    height: 7vh;
    border-radius: 6px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}
.closemodal_x{
    cursor: pointer;
    padding-top: 2px;
    z-index: 15;
    right: -65px;
    align-items: center;
    position: relative;
}
.modalactive_text{
    color: #008eff;
}
.back:hover{
    filter: brightness(65%);
}