button{
    border: none;
    border-radius: 6px;
    cursor: pointer;
}
.btn{
    color: #008eff;
}
.btn:hover{
    transition: .4s;
    color: #004f8f;
}

.btnLogin{
    color: white;
    background-color: #008eff;
    font-size: 20px;
    height: 50px;
    padding: 14px 16px;
    width: 100%;
    font-weight: bold;
}

.btnLogin:hover{
    transition: .4s;
    background-color: #0064b3;
}

.btnLoginCrear{
    color: white;
    background-color: #005191;
    font-size: 17px;
    height: 50px;
    padding: 14px 16px;
    width: 51%;
    font-weight: bold;
}

.btnLoginCrear:hover{
    transition: .4s;
    background-color: #4278a3;
}

#btnBlocked{
    background-color: #8d8d8d;
    cursor: not-allowed;
    pointer-events: none;
}
.btnActualizarMascota.block{
    background-color: #8d8d8d;
    cursor: not-allowed;
    pointer-events: none;
}

.btnRegistroRegistrate{
    height: 45px;
    padding: 0px 16px;
    background-color: #0089b3;
    margin: 12px 0px;
}

.btnRegistroRegistrate:hover{
    transition: .4s;
    background-color: #00779b;
}

.btnShort{
    color: white;
    background-color: #0072ff;
    font-size: 16px;
    height: 35px;
    padding: 5px 5px;
    width: 20%;
    font-weight: 100;
}

.btnShort:hover{
    transition: .4s;
    background-color: #004292;
}
.btnAgregarMascota {
    color: white;
    background-color: #006eff;
    font-size: 16px;
    height: 35px;
    padding: 5px 5px;
    width: 120px;
    font-weight: 100;
}
.btnAgregarMascota:hover{
    transition: .4s;
    background-color: #00469b;
}
.btnShort-pink{
    color: white;
    background-color: #d89fe1;
    font-size: 16px;
    height: 35px;
    padding: 5px 5px;
    width: 20%;
    font-weight: 100;
}

.btnShort-pink:hover{
    transition: .4s;
    background-color: #ce56e1;
}

.btnFilter-macho {
    background-color: white;
    color: #585858;
    font-size: 20px;
    height: 35px;
    padding: 5px 5px;
    width: 40px;
    font-weight: 800;
}
.btnFilter-macho:hover{
    background-color: #dcf7ff;
    color: #005eb6;
    transition: .4s;
}
.btnFilter-hembra {
    background-color: white;
    color: #585858;
    font-size: 20px;
    height: 35px;
    padding: 5px 5px;
    width: 40px;
    font-weight: 800;
}
.btnFilter-hembra:hover{
    background-color: #fce9ff;
    color: #ae00ff;
    transition: .6s;
}

.btnVermas {
    color: #1d2129;
    background-color: #ffffff;
    font-size: 15px;
    height: 35px;
    padding: 3px 0px;
    width: 100%;
    border: 1px solid #008eff;
    font-weight: 100;
}

.btnVermas:hover{
    transition:.4s;
    background-color: #008cff;
    color: #ffffff;
}

.btnCalendar{
    color: #ffffff;
    background-color: #008eff;
    font-size: 23px;
    height: 35px;
    padding: 6px 5px;
    width: 15%;
}

.btnCalendar:hover{
    transition:.4s;
    background-color: #005397;
    color: #ffffff;
}

.mt-1{
    margin-top: 5px;
}

.mt-3{
    margin-top: 10px;
}

.mt-5{
    margin-top: 20px;
}

.ml-1{
    margin-left: 5px;
}

.ml-3{
    margin-left: 10px;
}

.ml-5{
    margin-left: 20px;
}

.hidden{
    display: none;
}

.perfil_active{
    background-color: #ce56e1;
}

.btn200 {
    color: white;
    background-color: #008eff;
    font-size: 20px;
    height: 50px;
    padding: 14px 16px;
    width: 200px;
    font-weight: bold;
}

.btn200:hover{
    transition: .4s;
    background-color: #0064b3;
}

.btnRegistroMascota-cancel{
    align-items: center;
    display: flex;
    color: white;
    background-color: #f32727;
    font-size: 15px;
    height: 50px;
    justify-content: center;
    padding: 5px 16px;
    width: 25%;
    font-weight: bold;
}

.btnRegistroMascota-cancel:hover{
    transition: .4s;
    background-color: #ce0000;
}

.h_35{
    height: 35px;
    padding: 0;
}

.btnActualizarMascota{
    align-self: center;
    color: white;
    background-color: #006eff;
    font-family: 'Nunito', sans-serif;
    font-size: 13px;
    font-weight: 400;
    height: 30px;
    margin-top: 10px;
    padding: 5px 5px;
    width: 100px;
}
.btnActualizarMascota:hover{
    background-color: #0053c0;
    transition: .4s;
}

.btnAuto {
    color: white;
    background-color: #008eff;
    font-size: 15px;
    height: auto;
    padding: 7px 15px;
    width: auto;
    font-weight: 100;
}

.btnAuto:hover{
    transition: .4s;
    background-color: #0064b3;
}

button.btnLogin.disabled {
    pointer-events: none;
    background-color: lightgray;
}
