
@supports(object-fit: cover){
    
    .infoUser-modal .img-div img 
    
    {
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
}
