
.inputLogin{
    width: 100%;
    height: 50px;
    padding: 14px 16px;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size: 17px;
    color: #1d2129;
    font-family: 'Nunito', sans-serif;
}

.inputLogin:focus{
    outline: 1px solid #dddfe2;
}

.inputLogin:focus::placeholder{
    color: #1d212961;
}

.inputRegistro{
    padding: 11px;
    height: 43px;
}

.option_hidden{
    display: none;
}

/* .mt-5{
    margin-top: 20px;
} */

.inputH-100{
    height: 100%;
}

.inputEditMascota{
    width: 100%;
    height: 30px;
    padding: 8px 10px;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size: 13px;
    color: #1d2129;
}

.input-w95{
    width: 95px;
}
.input-w150{
    width: 150px;
}

.inputEditMascota:focus{
    outline: 1px solid #dddfe2;
}

.inputEditMascota:focus::placeholder{
    color: #1d212961;
}