@media (max-width: 800px) {
    .headerHome {
        padding: 12px 17px;
    }
    header {
        padding: 3% 2% 13%;
    }
    .headerHome__left h1 {
        font-size: 5rem;
        width: 100%;
    }
    .headerHome__left p {
        font-size: 23px;
        line-height: 33px;
        width: 100%;
    }
    .headerHome__right img {
        position: relative;
        width: 82%;
    }
    .home__aboutUs p {
        width: 100%;
    }
    .home__aboutUs-img {
        height: 382px;
    }
    .home__aboutUs-img img {
        width: 95%;
    }
    .home__proyecto {
        flex-direction: column;
        gap: 60px;
    }
    .home__proyecto-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 0px;
    }
    .home__aboutUs h1 {
        text-align: center;
    }
    .home__proyecto-img {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .home__proyecto-img img {
        height: 338px;
        width: 70%;
    }
    .home__aboutUs.q800 {
        display: flex;
        flex-direction: column-reverse;
        gap: 50px;
    }
    .home__tiempo {
        height: auto;
        width: 100%;
        padding: 12px 30px;
    }
    .home__tiempo-img.qimg800{
        width: 70%;
    }
    .home__tiempo.home__aboutUs-img{
        display: flex;
        justify-content: center;
    }
    .login_cont_iz img {
        display: none;
    }
    .home__contactUs-title {
        font-size: 5rem;
    }
}


@media (max-width: 600px) {
    header {
        padding: 2% 5% 0%;
    }
    #header__title {
        width: 170px;
    }
    .headerHome__left h1 {
        font-size: 5rem;
        width: 100%;
    }
    .headerHome__right{
        display: none;
    }
    .headerHome__left {
        height: 525px;
        width: 100%;
    }
    .home__aboutUs {
        display: flex;
        flex-direction: column-reverse;
        gap: 5%;
        padding: 4%;
        width: 100%;
    }
    .home__aboutUs-text {
        width: 100%;
    }
    .home__aboutUs-img {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .home__aboutUs-img img {
        width: 60%;
    }
    .home__aboutUs p {
        text-align: center;
    }
    .home__proyecto.q600{
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    .home__contactUs-title {
        font-size: 4rem;
        height: 105px;
    }
}


@media (max-width: 400px) {
    .headerHome {
        padding: 12px 10px;
    }
    .headerHome__right{
        display: none;
    }
    #header__title {
        width: 145px;
    }
    #liHome {
        list-style: none;
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .aNavHome {
        width: 65px;
        height: 35px;
    }
    .nav_Home {
        gap: 7px;
    }
    header {
        background: linear-gradient(360deg, #0051b5,#0072ff);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        padding: 3% 1% 25%;
        overflow: hidden;
    }
    .headerHome__left p {
        font-size: 20px;
        width: 100%;
    }
    .headerHome__left {
        width: 85%;
    }
    .headerHome__left h1 {
        font-size: 4rem;
        font-family: 'Bebas Neue', cursive;
        margin-bottom: 2%;
        letter-spacing: 8px;
        width: 100%;
        line-height: 4.8rem;
    }
    .home__aboutUs {
        display: flex;
        flex-direction: column-reverse;
        gap: 45px;
        padding: 5px;
        width: 100%;
    }
    .home__aboutUs-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 0px;
    }
    .home__aboutUs p {
        color: #363636;
        font-size: 20px;
        width: 100%;
    }
    .home__aboutUs-img {
        height: 250px;
        width: 100%;
        justify-content: center;
        padding: 0px 0px;
    }
    .home__aboutUs-img img {
        height: 235px;
        width: 85%;
    }
    .home__aboutUs.nuestro{
        display: flex;
        flex-direction: column;
        gap: 45px;
        padding: 30px;
        width: 100%;
    }
    .home__proyecto-text {
        width: 100%;
    }
    .home__proyecto-img {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .home__contactUs-title {
        font-size: 3rem;
    }
    footer {
        margin-top: 0px;
    }
}