.agenda__container{
    align-items: center;
    background-color: white;
    display: flex;
    height: auto;
    justify-content: center;
    overflow: scroll;
    padding: 10vh 90px;
}

#selected #spanMascota{
    color: #bebebe;
}

.agenda__container .card{
    border-radius: 6px;
    box-shadow: 0px 0px 7px 0px lightgrey;
    min-height: 500px;
    width: 90%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 5vw;
    gap: 4vw;
    min-width: 800px;
}

.agenda__container .card p::selection{
    background: #c8e0ff;
}

.agenda__container .card .title{
    background-color: #008eff;
    border-radius: 6px;
    padding: 10px 30px;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top: -20px;
    color: white;
    font-size: 22px;
    left: 40px;
    text-transform: capitalize;
}

.agenda__container .card .selectors{
    width: 50%;
    min-height: 200px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.agenda__container .card .imgSchedule{
    width: 50%;
    height: 100%;
    gap: 50px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.agenda__container .card .selectors #select{
    width: 100%;
    min-width: 200px;
    height: 45px;
}


.agenda__info-veterinarios{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
.agenda__button-card{
    background-color: white;
    border-radius: 4px;
    text-align: left;
    transition: margin-left .4s;
}
.agenda__veterinarios-card{
    box-shadow: 0px 0px 2px 0px lightgray;
    border-radius: 4px;
    width: 120px;
    height: 140px;
    position: relative;
}
.agenda__veterinarios-card img{
    border-radius: 4px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.agenda__veterinarios-card h1{
    background-color: #0000009e;
    border-radius: 4px;
    align-items: center;
    font-size: 15px;
    justify-content: center;
    position: absolute;
    top: 0;
    display: flex;
    text-align: center;
    width: 100%;
    color: white;
    height: 100%;
    transition: .4s;
}
.agenda__veterinarios-card h1:hover{
    background: none;
    color: #ffffff00;
    transition: .4s;
}
.agenda__veterinarios-card .vet_active{
    background: none;
    border: 3px solid #ff8d61;
    color: #ffffff00;
}
.agenda__veterinarios-card .vet_active:hover{
    background-color: #0000009e;
    color: white;   
    transition: background-color .4s;
}
.agenda__veterinarios-card p{
    font-size: 13px;
    padding: 0px 5px;
}



.agenda__info-schedules{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width: 50%;
}



.imgSchedule .imgPet .pet {
    max-width: 200px;
    border-radius: 2px;
    min-width: 100px;
    min-height: 100px;
    max-height: 200px;
} 
.imgSchedule .imgPet{
    align-self: center;
    display: flex;
    justify-content: space-evenly;
    width: 80%;
}
.imgSchedule .imgPet .info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.imgSchedule .imgPet .info .titulo{
    display: flex;
    gap: 10px;
    font-size: 25px;
}
.imgSchedule .imgPet .info .infodata {
    font-size: 15px;
}

.imgSchedule .imgPet .info .infodata.more{
    font-size: 13px;
}


.imgSchedule .schedule {
    width: 100%;
    min-width: 350px;
    height: 200px;
    overflow: scroll;
} 
.agenda__container .imgSchedule .schedule .day{
    background-color: #008eff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: white;
    font-size: 20px;
    padding: 10px;
    text-align: center;
    width: 100%;
}

.agenda__container .imgSchedule .schedule .momento{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
}
.agenda__container .imgSchedule .schedule .momento .columna{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}

.agenda__container .imgSchedule .schedule .momento .columna .h2{
    color: black;
    font-size: 15px;
    font-weight: 100;
}

.agenda__container .imgSchedule .schedule .momento .columna .btn{
    
    padding: 5px 15px;
    color: black;
    border-radius: 50%;
    transition: .4s;

}
.agenda__container .imgSchedule .schedule .momento .columna .btn:hover{
    
    background-color: aliceblue;
    color: black;
    transition: .4s;

}

.agenda__container .imgSchedule .schedule .momento .columna .btn.hourActive{
    color: white;
    background-color: #008eff;
}

#selected{
    background-color: #e9e9e9;
    pointer-events: none;
}

.horarioEmpty{
    width: 100px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

.agenda__container .imgSchedule .schedule .momento .columna .small{
    background-color: #018eff;
    border-radius: 50%;
    color: white;
    font-size: 10px;
    padding: 3px;
}

.agendaDescription{
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 40px;
    min-width: 400px;
    width: 600px;
    position: relative;
    z-index: 1000;
}
.agendaDescription .cancel{
    background-color: #ff3c3c;
    border-radius: 50%;
    font-size: 1rem;
    height: 20px;
    width: 20px;
    color: white;
    top: 10px;
    right: 10px;
    position: absolute;
    transition: .5s;
}
.agendaDescription .cancel:hover{
    background-color: #ff7272;
    transition: .5s;
    color: white;
}
.agendaDescription .h1,.p,.small,.description{
    font-family: 'Nunito', sans-serif;
}
.agendaDescription .description{
    border:2px solid #81c6ff;
    border-radius: 6px;
    padding: 10px;
    resize: none;
    margin-top: 25px;
    height: 130px;
    width: 100%;
}
.agendaDescription .description:focus{
    border: none;
    outline:3px solid #a7d7ff;
}
.agendaDescription .h1{
    color: #008eff;
}

.agenda_success{
    align-items: center;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 60px 40px;
    z-index: 1000;
}
.agenda_success .h1{
    color: green;
    font-size: 20px;
}
.agenda_success .icon{
    background-color: green;
    border-radius: 50%;
    color: white;
    font-size: 30px;
    padding: 10px;
    width: 50px;
    height: 50px;
}
.agenda_success .buttons{
    display: flex;
    gap: 20px;
    margin-top: 20px;
}