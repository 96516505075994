form{
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.contenedor__schedules{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100px;
    overflow-y: scroll;
    width: 100%;
}

.days_register{
    display: flex;
    width: 63%;
    justify-content: space-between;
    color: #1d2129;
}

.div_select_days_register{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    height: auto;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.day_option_register{
    align-items: center;
    background-color: #bfe2ff;
    border-radius: 6px;
    color: #1d2129;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    letter-spacing: 1px;
    width: 20%;
}

.day_option_register:hover{
    background-color: #008eff;
    color: white;
    border: 2px solid #008eff;
}

.day_register_active{
    background-color: #008eff;
    color: white;
    border: 2px solid #008eff;
}

.days_time_register {
    border-bottom: 1px solid #009bc9;
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 100%;
}

.days_time_register:nth-child(2n - 1) {
    background-color: aliceblue;
}

.days_time_register input {
    background-color: #ffffff00;
}

.days_time_register p{
    width: 170px;
    font-size: 12px;
}

.time_register{
    align-items: center;
    display: flex;
    font-size: 12px;
    gap: 5px;
}

.time_register input{
    outline: none;
    border: none;
}

.hidden{
    display: none;
}

.show{
    display: block;
}


/* REGISTRO VETERINARIO Y USUARIO */

#div_100{
    justify-content: center;
    display: flex;
    width: 100%;
}

.h4_register_clinica{
    align-self: flex-start;
    align-items: center;
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 68%;
    color: #008eff;
}

.cont_icon{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.user_icon{
    align-items: center;
    background-color: #008eff;
    border-radius: 6px;
    color: white;
    display: flex;
    font-size: 22px;
    height: 25px;
    justify-content: center;
    text-align: center;
    width: 35px;
}

#select{
    padding: 11px;
    height: 43px;
    width: 100%;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size: 17px;
    color: #1d2129;
}

#select:focus{
    outline: none;
}

/* WARNINGS USER-REGISTER */
.warn__password-user {
    height: 20px;
    margin-top: -15px;
    margin-bottom: 5px;
    font-size: 14px;
    color: #ff0000;
}

.spiner {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    animation: spin 1.5s infinite linear;
}

@keyframes spin {
    to{
        transform: rotate(1turn);
    }
}
.regiter-succesfuly{
    position: absolute;
    align-items: center;
    border: 2px solid green;
    border-bottom-right-radius: 10px;
    background-color: rgb(194, 255, 194);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    padding: 10px 20px;
    top: 0px;
    left: 0px;
    width: 200px;
}
.regiter-succesfuly p {
    color: green;
    margin-left: 10px;
}

.hidden{
    display: none;
}


#register_spiner{
    position: relative;
}
.register__loading-div{
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

/* CLINICA */
.registerClinica__form {
    height: auto;
}
.registerClinica__info{
    background-color: #ffe5a0;
    border-radius: 3px;
    color: #1d2129;
    padding: 10px;
    text-align: center;
}
#registerUser__error{
    color: red;
}

.registerUser__registered{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.registerUser__registered p{ 
    color: #007306;
}