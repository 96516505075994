
*{
    margin: 0;
    padding: 0;
}

.horarios_veterinarios_comp{
    display: flex;
}

.gestion_space{
    display: flex;
    gap: 15px;
}
.title_profile_clinic{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile_clinica h1,h2,h3,h4,h5,h6,input,button,span{
    font-family: 'Ruda', sans-serif;
    color: #0072ff;
}
.profile_clinica{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section_profile_1_clinics{
    display: flex;
    width: 155vh;
}
.section_profile_3_clinics{
    background: linear-gradient(360deg, #61bbd6,#0072ff);
    height: 25vh;
}
/* Perfil Left */
.section_profile_4_clinics{
    margin-top: -16rem;
    z-index: 10001;
    width: 20vw;
    background-color: rgb(255, 255, 255);
    height: 95vh;
}
.img_profile_clinic{
    width: 20vw;
    height: 20vw;
    align-items: center;
    display: flex;
    justify-content: center;
}
.img_cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Perfil Editable */
.perfil{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.perfil_hidde{
    display: none;
}
.perfil_show{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Bottom section */
.section_bottom{
    display: flex;
    flex-direction: row;
    gap: 5rem;
    justify-content: space-between;
}

/* Tabla horarios */
.margintop{
    margin-top: 15vh;
}
.horarios_define{
    display: flex;
    /* justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    width: 65vh;
    padding: 2vh;
    position: relative; */
}
.horarios_define_hidde{
    display: none;
}
.horarios_define_show{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 145vh;
    padding: 2vh;
    position: relative;
}
.part1_horarios{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.part2_horarios{
    display: flex;
    flex-direction: row;
}
/* Cards_Horarios*/
.card_dia{
    width: 25vh;
    height: 25vh;
    display: flex;
    justify-content: flex-start; 
}
.dia{
    background-color: #ebebeb;
    width: 20vh;
    height: 23vh;
    border-radius: 6px;
    position: absolute;
}
.head_dia{
    position: relative;
    background-color: #008eff;
    height: 5vh;
    width: 12vh;
    margin-left: 38px;
    margin-top: -22px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.hours{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.input_time{
    border-radius: 25px;
    border: none;
    height: 2rem;
    width: 15vh;
    text-align: center;
    color: black;
}
.cont{
    text-align: center;
}
.title_hour{
    margin-bottom: 2rem;
}
.btn_marg{
    margin: 0;
}
.title_cont{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.input_text_p{
    color: #000000;
    border-radius: 25px;
    border: none;
    padding-left: 27px;
    width: 15vh;
    text-align: center;
    font-size: 1rem;
}
.input_time:focus{
    outline: 0cm;
}
.hours_1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hours_2{
    margin-top: 5px;
}
.cont_chb_input{
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 10002;
}
.checkbox_input{
    width: 20px;
    height: 20px;
}
/* Lista Veterinarios Horario Veterinarios */
.list_vets{
    height: 30vh;
    overflow-y: scroll;
    width: 45vh;
    display: flex;
    flex-direction: column;
}
.li_horarios{
    width: 40vh;
    height: 3rem;
    margin-left: 10px;
    cursor: pointer;
}
.li_horarios:hover{
    filter: brightness(85%);
}
.li_select{filter: brightness(85%);}
.cont_vet_li{
    display: flex;
    flex-direction: column;
}
.space_li{
    gap: 20px;
    margin: 0;
    padding: 0;
}
/* Modal Vets Horario */
.modal_vets{
    background-color: #ebebeb;
    z-index: 1000;
    width: 45vh;
    height: 20vh;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border: solid 4px #008eff;
    border-top: solid #008eff 30px;
}