
.register_container{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #000000cc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
}



.registerData{
    background-color: rgb(255, 255, 255);
    width: 550px;
    height: 520px;
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    gap: 15px;
    position: relative;
}

.registerDataAuto{
    background-color: rgb(255, 255, 255);
    width: 550px;
    height: auto;
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    gap: 15px;
    position: relative;
}

#MdOutlineCancel {
    font-size: 33px;
    color: #ff7d7d;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

#MdOutlineCancel:hover {
    transition: .4s;
    color: #a50000;
}

#titulo_MdOutlineCancel {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#titulo_MdOutlineCancel h2 {
    color: #008eff;
}

#titulo_MdOutlineCancel1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 215px;
    justify-content: center;
}
#titulo_MdOutlineCancel1 h2 {
    color: #008eff;
}

#register_steps{
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.step{
    width: 35px;
    height: 35px;
    border: 2px solid #bababa;
    color: #bababa;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linea_step{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    margin-top: -4px;
    font-size: 21px;
    color: #bababa;
    height: 100%;
}


.color_step{
    color: white;
    border:  2px solid #008eff;
    background-color: #008eff;
}

.color_linea{
    color: #0089b3;
}

#p1Registro{
    padding: 0;
    font-size: 15px;
    color: #606770;
}

#registro_column1{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

#registro_cuenta_existente{
    cursor: pointer;
    color: #1877f2;
    text-decoration: none;
}

.registro_sexo{
    width: 100%;
    display: flex;
    flex-direction: column;
}
#p2Registro{
    padding: 0;
    font-size: 13px;
    color: #606770;
}

.registro_checkbox{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid #dddfe2;
    border-radius: 6px;
}

.registro_checkbox p{
    font-size: 15px;
    padding: 0;
}

.cont_registro_checkbox{
    display: flex;
    flex-direction: row;
    gap: 10px;
}


/* ------------- DISEÑO TARJETAS STEP 1 ------------- */
#registerType{
    height: auto;
}

.types_users{
    justify-content: space-evenly;
    display: flex;
    margin: 15px 0px;
    width: 60%;
}

.type__user-button:hover{
    color: white;
    background-color: #008eff;
    transition: .5s;
}
.type__user-button:hover .type__user-button-icon{
    color: white;
}
.type__user-button:hover .type__user-button-h1{
    color: white;
}

#type__user-active{
    color: white;
    background-color: #008eff;
    transition: .5s;
}
#type__user-active h1,.type__user-button-icon{
    color: white;
}
#type__user-active .type__user-button-icon{
    color: white;
}

.type__user-button{
    background-color: white;
    border: 2px solid #008eff;
    border-radius: 100%;
    height: 100px;
    transition: .4s;
    width: 100px;
}
.type__user-button-icon{
    color: #008eff;
    font-size: 40px;
}

.type__user-button-h1{
    color: #008eff;
    font-size: 15px;
}

.warn_check{
    color: #535353;
}

.terminos-condiciones{
    align-items: center;
    display: flex;
    gap: 10px;
    color: #606770;
}
.terminos-condiciones .anchor{
    margin: 0;
    color: #008eff;
    text-decoration: none;
}

/* --------------------------------- */

.rows_register{
    font-size: 40px;
    cursor: pointer;
    color: #008eff;
    height: 25px;
}

.rows_register:hover{
    transform: scale(1.1);
    transition: .4s;
    color: rgb(0, 110, 144);
}


