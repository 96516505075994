.contenedor__clinicas{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    height: auto;
    padding: 100px 9%;
    width: 100%;
}
.contenedorClinicasMenu{
    display: flex;
    justify-content: center;
    margin-top: 24px;

}



.menuBar{   
    display: flex;
    align-items: center;
    position: fixed;
    flex-direction: column;
    background-color: #008eff;
    border-radius: 50%;
    color: white;
    gap: 6px;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    padding: 10px;
    top: 20px;
    left: 20px;
    transition: 1s;
}
.menuBar.change{
    left: 50px;
    transition: 1s;
    
}
.menuBar:hover{
    background-color: #036cc2;
}
.iconMenu{
    display: inline-block;
    align-items: center;
    
    
}

.bar1, .bar2, .bar3 {
    border-radius:6px;
      width: 25px;
      height: 3px;
      background-color: #ffffff;
      transition: 0.4s;
    }

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
    margin-bottom: 4px;
    }
    
.change .bar2 {opacity: 0;}

.change .bar3 {
-webkit-transform: rotate(45deg) translate(-8px, -8px);
transform: rotate(45deg) translate(-8px, -8px);
}

.menuClinicas{
    background-color: #008eff;
    border: 1px solid #008eff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 12px;
    height: 150px;
    padding: 10px 0px;
    justify-content: flex-end;
    left: 10px;
    width: 125px;
    min-width: 125px;
    z-index: 1;
}

.btnOpcionesC{
    color: white;
    border-radius: 0;
    text-decoration: none;
    cursor: pointer;
    padding: 10px 11px;
    text-align: center;
    transition: all 0.4s;
}
.btnOpcionesC:hover
{
    transition: all 0.4s;
    background-color: white;
    color: #008eff;
}
.cover{
    background: rgba(32,35,41,.8);
    position: fixed;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#tilte__clinicas{
    font-family: 'Nunito', sans-serif;
    font-weight: 100;
    font-size: 50px;
    height: 130px;
    text-align: center;
    width: 100%;
}

#tilte__clinicas span{
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    color: #008eff;
}

.animated_arrow{
    position: relative;
    color: #008eff;
    animation-name: mover;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0ms;
}

@keyframes mover{
    0% {
        top: 0;
    }

    50%{
        top: 10px;
    }

    100%{
        top: 0;
    }
}

.card__cli{
    color: #26272a;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 310px;
}

.card__cli img {
    width: 100%;
    height: 350px;
    border-radius: 10px;
    object-fit: cover;
}

.card__cli h1{
    font-family: 'Nunito', sans-serif;
    font-size: 30px;
}

.card__cli h2{
    align-items: center;
    display: flex;
    font-size: 17px;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    gap: 10px;
}

.card__cli h3{
    align-items: center;
    display: flex;
    font-size: 17px;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    gap: 10px;
}

.card__cli h3 b{
    font-family: 'Nunito', sans-serif;
}

.card__cli p{
    font-size: 13px;
    font-family: 'Nunito', sans-serif;
}

.card__cli span{
    color: #008eff;
    font-size: 23px;
    font-weight: bold;
    margin-bottom: -5px;
}

.hr__clinicas{
    background-color: #77889966;
    border: none;
    border-radius: 100%;
    height: 1px;
}

.buttons_clinicas{
    display: flex;
    gap: 10px;
    width: 100%;
}


.clinica__mainContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 100vh;
    overflow: scroll;
    padding: 50px 0px;
}
.contenedor__clinica_individual{
    box-shadow: 1px 1px 9px 0px;
    border-radius: 6px;
    align-items: center;
    display: flex;
    padding: 50px 20px;
    flex-direction: column;
    justify-content: center;
    min-width: 500px;
    height: auto;
}
.card__cli .txt{
    color: black;
}

.clinica__data h1,h2,h3,p,span {
    font-family: 'Nunito', sans-serif;
}
.clinica__data .txt{
    color: black;
}

.clinica__data span {
    color: #008eff;
    font-size: 20px;
    margin-bottom: -10px;
}

.clinica__data h1{
    display: flex;
    font-size: 30px;
    justify-content: space-between;
}

.clinica__data h2{
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 100;
    gap: 10px;
}

.clinica__data h3 b {
    font-weight: 200;
}

.clinica__data h3{
    align-items: center;
    display: flex;
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    font-weight: 100;
    gap: 10px;
}

.clinica__img{
    width: 350px;
    height: 300px;
}

.clinica__img img {
    border-radius: 10px;
    width: 350px;
    height: 300px;
    object-fit: cover;
}

.clinica__data{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 350px;
}

.cli__schedule{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    width: 400px;
    font-size: 13px;
}

.contenedor__clinica_individual summary{
    align-items: center;
    list-style: none;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    gap: 10px;
    font-weight: 100;
    width: 190px;
}

.cli__schedule:nth-child(2n - 1){
    background-color: #77889966;
}

#clinica__summary-icon{
    color: #008eff;
}

.clinica__enlace{
    font-size: 20px;
    text-decoration: none;
}