
.admin__container{
    display: flex;
    flex-direction: column;
}
.admin__nav{
    background-color: lightgray;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    padding: 10px 40px;
    width: 100%;
}
.admin__nav-izquierdo{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.btnAdmin{
    background-color: #0072ff;
    color: white;
    height: 100%;
    width: 100px;
}
.btnAdmin:hover{
    background-color: #004ca8;
    transition: .5s;
}
.btnAdmin.active{
    background-color: #004ca8;
    transition: .5s;
    text-decoration: underline;
}
.btnAdmin.peticiones{
    background-color: rgb(0, 158, 71);
    position: relative;
}
.btnAdmin.peticiones .number{
    position: absolute;
    top: -7px;
    right: -5px;
    background: #414f63;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnAdmin.peticiones:hover{
    background-color: rgb(0, 120, 54);
}

.admin__container-info{
    padding: 10px 5%;
    width: 100%;
}
.activeBtnAdmin{
    background-color: #004ca8;
}
/* Filter */
.admin__filter{
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    margin-top: 30px;
    padding: 10px 50px;
    width: 100%;
}
.admin__filter label{
    font-family: 'Nunito', sans-serif;
    margin-right: 10px;
}
.admin__filter input{
    border: 1px solid black;
    border-radius: 50px;
    color: black;
    height: 30px;
    outline: none;
    padding: 5px 10px;
    width: 150px;
}

#search {
    border-radius: 50px;
    height: 30px;
    margin-left: 10px;
    width: 80px;
}

/* .............. Info User .............. */
.infoUser__container{
    background-color: #0072ff;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: space-between;
    padding: 10px 30px;
    width: 40vw;
}
.infoUser__data{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.infoUser__data span{
    color: white;
    font-size: 10px;
}
.infoUser__data h1{
    color: white;
    font-size: 15px;
    font-weight: 100;
}
.infoUser__data h2{
    color: white;
    letter-spacing: 1px;
    font-size: 1rem;
}
.infoUser__managment{
    display: flex;
    flex-direction: row;
    gap: 5px;
    background: none;
}
.infoUser__managment .casilla{
    align-items: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    height: 30px;
    width: 30px;
    margin: 0;
}
.infoUser__managment .edit{
    background-color: #00d100;
    color: white;
    font-size: 15px;
    font-weight: 400;
}
.infoUser__managment .show{
    background-color: #97c0ff;
    color: white;
    font-size: 15px;
    font-weight: 400;
}
.infoUser__managment .delete{
    background-color: #ff3737;
    color: white;
    font-size: 15px;
    font-weight: 400;
}
.infoUser__managment .edit:hover{
    background-color: #00a900;
    transition: .4s;
}
.infoUser__managment .delete:hover{
    background-color: #ce1818;
    transition: .4s;
}
.infoUser__managment .show:hover{
    background-color: #4c6997;
    transition: .4s;
}

.infoUser__container button{
    background: none;
}
.admin__peticiones-modal{
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 50vh;
    padding: 4vw;
    position: absolute;
    width: 50vw;
    z-index: 1000;
}
.admin__peticiones-modal .cancel{
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    right: 0px;
    top: 0px;
}
.admin__peticiones-modal .cancel p{
    color: #666666;
    width: 25px;
    text-align: center;
}
.admin__peticiones-modal .cancel p:hover{
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #ffffff;
    transition: .4s;
    background-color: #ff3535;
}

.admin__peticiones-modal .titulo{
    color: #0072ff;
}

.admin__peticiones-modal .peticiones{
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 80%;
    padding: 2vw;
    width: 100%;
    overflow-y: scroll;
}

/* .............. Peticiones Clínicas ................ */
.peticionesCli__container{
    align-items: center;
    background-color: #0072ff;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    width: 100%;
    padding: 0.7vw;
    text-align: left;
}
.peticionesCli__container .nit{
    color: white;
    font-size: 1rem;
}
.peticionesCli__container .nombre{
    color: white;
    font-size: 15px;
}
.peticionesCli__button{
    color: white;
    height: 30px;
    width: 65px;
}
.peticionesCli__button.aceptar{
    background-color: #12d800;
}
.peticionesCli__button.denegar{
    background-color: #ff3535;
    margin-left: 5px;
}
.peticionesCli__button.aceptar:hover{
    background-color: #0c8b00;
    transition: .4s;
}
.peticionesCli__button.denegar:hover{
    background-color: #bd0000;
    transition: .4s;
}
.hidden{
    display: none;
}
.peticiones__refreh{
    color: #0072ff;
    cursor: pointer;
    font-size: 50px;
    border-radius: 6px;
    height: 80px;
}
.peticiones__refreh:hover{
    color: #72b1ff;
    transition: .4s;
}
.admin__peticiones-modalVerify{
    padding: 20px;
    background-color: #bd0000;
    z-index: 1000;
    position: absolute;
    top: 0;
}

.infoUser-modal {
    align-items: center;
    border: 2px solid #0072ff;
    position: relative;
    border-radius: 5px;
    background-color: white;
    color: #666666;
    display: flex;
    flex-direction: row;
    height: auto;
    padding: 4vh 3vw;
    min-width: 500px;
    justify-content: space-between;
    gap: 20px;
    z-index: 1000;
}
.infoUser-modal .title_modal {
    background-color: white;
    border: 2px solid #0072ff;
    border-radius: 5px;
    color: #0072ff;
    font-size: 20px;
    height: 40px;
    padding: 6px 5%;
    position: absolute;
    text-align: center;
    top: -20px;
    width: auto;
}
.infoUser-modal .status {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    right: 0;
    font-weight: 800;
    height: 30px;
    width: 40px;
}
.infoUser-modal .status.active{
    color: white;
    background-color: #0072ff;
}
.infoUser-modal .status.disabled{
    color: white;
    background-color: #ff3535;
}
.infoUser-modal .status.waiting{
    color: white;
    background-color: #0c8b00;
}
.infoUser-modal .status.cancel{
    color: #888888;
    cursor: pointer;
    top: 10px;
    right: 5px;
}
.infoUser-modal .status.cancel:hover{
    color: #e43f3f;
    transition: .4s;
}

.infoUser-modal .img-div{
    height: 200px;
    width: 200px;
}
.infoUser-modal .img-div img{
    border-radius: 100%;
    height: auto;
    width: 100%;
}
.infoUser-modal .info-div{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 250px;
    justify-content: center;
    width: 55%;
}
.infoUser-modal .info-div .icon{
    color: #0072ff;
    margin-right:7px;
}
.infoUser-modal .info-div p{
    word-break: break-all;
    font-size: 20px;
}


/* ............. TABLE USER ............. */

.tableUsuarios {
    align-self: center;
    min-width: 800px;
    padding-left: 10px;
    padding-right: 10px;
}

.tableUsuarios h2 {
    font-size: 26px;
    margin: 20px 0;
    text-align: center;
}
  
.tableUsuarios  .responsive-table li{
    border-radius: 3px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    gap: 10px;
    text-align: center;
    position: relative;
}
.tableUsuarios .table-header {
    background-color: #008eff;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}
.table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
}
.col{
    display: flex;
    align-items: center;
    justify-content: center;
}
.col-1 {
    width: 20%;
}
.col-2 {
    width: 20%;
}
.col-3 {
    width: 40%;
}
.col-4 {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#login-spin-adminScreen {
    border-bottom: 2px solid #008eff;
    border-top: 2px solid #008eff;
    border-left: 2px solid #ffffff00;
    border-right: 2px solid #ffffff00;
    position: relative;
    width: 50px;
    height: 50px;
    align-self: center;
    top: 20vh;
}

.buttonPaginacion{
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.buttonPaginacion .btn{
    display: flex;
    background-color: #008eff;
    color: white;
    font-size: 20px;
    padding: 5px 15px;
    width: 50px;
}

.input.number{
    width: 80px;
    padding: 5px 5px 5px 15px;
    height: 25px;
    margin-left: 50px;
}
.input.number:focus{
    outline: none;
}
.paginacionp{
    margin-left: 50px;
    margin-top: 20px;
}

.update__container{
    z-index: 1000;
}

.delete-modal{
    border-radius: 6px;
    padding: 40px;
    background-color: white;
    z-index: 1000;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#optionEstadoa{
    color: #00d100;
}
#optionEstadob{
    color: #ff1717;
}
.statusa{
    color: #00d100;
}
.statusb{
    color: #ff1717;
}
span.estado-point{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 20px;
}
span.estado-point.a{
    background-color: #12d800;
}
span.estado-point.b{
    background-color: #ff3232;
}
span.estado-point.c{
    background-color: #ffd900;
}