.modal_perfil_no_autorizado{
    align-items: center;
    background-color: #2196f37d;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
    overflow: scroll;
}
.modal_perfil_no_autorizado_info {
    background-color: white;
    box-shadow: -13px 11px 9px 0px #85c9ff;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    justify-content: space-between;
    padding: 50px 40px;
    width: 50%;
    position: relative;
}
.modal_perfil_no_autorizado img {
    position: absolute;
    top: -90px;
    width: 200px;
}

.modal_perfil_no_autorizado-registro{
    color: #565656;
    margin-top: 15px;
}
.modal_perfil_no_autorizado-registro a {
    color: #008eff;
    font-weight: 800;
    text-decoration: none;
}