.modal{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.63);
    display: none;
}
.modal.modalOpen{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalContainer{
    position: relative;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    color: #616161;
    border-radius: 6px;
    width: 80vh;
    height: 80vh;
    overflow-y: scroll;
    padding: 1rem;
}
#MdOutlineCancelVet {
    font-size: 33px;
    color: #ff7d7d;
    cursor: pointer;
    margin: 0;
}

#MdOutlineCancelVet:hover {
    transition: .4s;
    color: #a50000;
}
.btn_close_container{
    width: 75vh;
    height: 3vh;
    display: flex;
    justify-content: flex-end;
}
.img_cont_1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}
.img_vet_form_reg:hover{
    transition: 0.2s;
    filter: brightness(50%);
}
.img_vet_form_reg{
    filter: brightness(70%);
    border: rgb(199, 199, 199) 2px dotted;
    transition: 0.2s;
    width: 200px;
    height: 200px;
    margin-bottom: 15px;
}
.icon_vet_img{
    font-size: 4rem;
    position: absolute;
    color: azure;
}
/* //////////////////// */
.input.selects{
    width: 35vh;
}
.input.regs{
    padding: 20px;
    width: 35vh;
}
.input_registerVet_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.parts{
    display: flex;
    flex-direction: row;
    gap: 5px;
}
/* ///////////////////// */
.submit.vetRes{
    width: 25vh;
}